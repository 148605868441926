import React from 'react';
import playMarket from '../../_assets/images/playMarket.png'

function MarketPlay() {
  const url = `${window.location.origin}/server/folder/media/playMarketApp/app-release.apk`;
  return (
    <a href={url} download>
      <img alt='' src={playMarket} />
    </a>
  );
}

export default MarketPlay;
